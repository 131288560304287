<template>
  <section id="schema-ecommerce">
  
    <b-card class=" W-100">
      <b-card-header
        style="background-color:#012951;color:#fffff"
      >
        <b-card-title>Schema Statistics
        </b-card-title>
      </b-card-header>
      <b-card-body
        class="my-10"
        style="margin-top: 10px;"
      >
        <b-row>

          <b-col xl="4" md="12" sm="12" >
            <b-card class="">
              <div class="text-center">
                <h4 class="mt-5">
                  General Course
                  <b-icon icon="grid" />
                </h4>
              </div>

              <b-card-body>
                <b-row class="text-center">
                  <b-col
                    cols="12"
                    class="count"
                  >
                    {{ Statsitic.general_courses_count }}
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="4" md="12" sm="12" >
            <b-card class="">
              <div class="text-center">
                <h4 class="mt-5">
                  Classical Course
                  <b-icon icon="journal-bookmark" />
                </h4>
              </div>

              <b-card-body>
                <b-row class="text-center">
                  <b-col
                    cols="12"
                    class="count"
                  >
                    {{ Statsitic.classic_courses_count }}
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="4" md="12" sm="12" >
            <b-card class="">
              <div class="text-center">
                <h4 class="mt-5">
                  Online Course
                  <b-icon icon="journal-bookmark-fill" />
                </h4>
              </div>

              <b-card-body>
                <b-row class="text-center">
                  <b-col
                    cols="12"
                    class="count"
                  >
                    {{ Statsitic.online_courses_count }}
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
           <b-row>
          <b-col xl="6" md="12" sm="12" >
            <b-card class="">
              <div class="text-center">
                <h4 class="mt-5">
                  Post
                  <b-icon icon="map" />
                </h4>
              </div>

              <b-card-body>
                <b-row class="text-center">
                  <b-col
                    cols="12"
                    class="count"
                  >
                    {{ Statsitic.posts_count }}
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
             <b-col xl="6" md="12" sm="12" >
            <b-card class="">
              <div class="text-center">
                <h4 class="mt-5">
                  Event
                  <b-icon icon="map" />
                </h4>
              </div>

              <b-card-body>
                <b-row class="text-center">
                  <b-col
                    cols="12"
                    class="count"
                  >
                    {{ Statsitic.event_count }}
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>
 
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    apexchart: VueApexCharts,
    BCardHeader,
    flatPickr,
    BCard,
    BCardTitle,
  },

  data() {
    return {
      Userdata: {},

      end_date: '',
      start_date: '',
      Statsitic: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
         
        },
        dataLabels: {
          enabled: true,
          style: {
            // colors: ["#64699a","#f6832d"]
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Count',
          },
        },
      },
      chartData: [],

    }
  },
 
  created() {
   
    this.$store.dispatch('setting/getStatsiticSchema').then(response => {
      this.Statsitic = response.data
      console.log(response.data)
    })
  },
  methods: {
 
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
h4.card-title {
  color: #fff;
}
.col-12 {
  margin: 15px;
}
.label {
  font-weight: 900;
  margin: 5px;
}
.count {
    font-size: 20px;
    font-weight: 600;
}
</style>
